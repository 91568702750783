import React from "react"
import PropTypes from "prop-types"

const Tiles = ({ tag, auto, children, className, ...props }) => {
  const Tag = tag
  const classNames = ['hcc-tiles', 'hcc-grid']

  if ( auto )      { classNames.push('is-auto') }
  if ( className ) { classNames.push(className) }

  return (
    <Tag className={classNames.join(' ')} {...props}>
      {children}
    </Tag>
  )
}

Tiles.defaultProps = {
  tag       : 'ul',
  auto      : false,
  className : '',
  children  : null,
}

Tiles.propTypes = {
  tag       : PropTypes.string,
  auto      : PropTypes.bool,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Tiles
