import React from "react"
import PropTypes from "prop-types"

const Jumbotron = ({h, prefix = '', content, className, children, ...props}) => {

  const Tag = `h${h}`

  return (
    <div className="hcc-jumbotron" {...props}>
      <Tag className="hcc-jumbotron-title">
        {prefix !== '' && <span className="hcc-jumbotron-title-prefix">{prefix}</span>}
        {content}
      </Tag>
      {children}
    </div>
  )

}

Jumbotron.defaultProps = {
  h         : '1',
  content   : null,
  className : '',
  children  : null,
}

Jumbotron.propTypes = {
  h         : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content   : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  className : PropTypes.string,
  children  : PropTypes.object,
}

export default Jumbotron
