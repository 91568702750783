import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "../components/Button"
import Svg from "../components/Svg"
import { gimmeTheTag } from "../helpers"

const Tile = ({ tag, size, cardinality, href, category, title, subtitle, cta, children, className, ...props }) => {

  const [active, setActive] = useState(false)
  const Tag                 = tag
  const Anchor              = gimmeTheTag(href, tag)
  const classNames          = ['hcc-tile', `is-${size.replace('/', '-')}`, `is-${cardinality}`]

  if ( href )      { classNames.push('is-anchor') }
  if ( className ) { classNames.push(className)   }
  if ( active )    { classNames.push('is-active') }

  const onFocus = () => {
    setActive(true)
  }

  const onBlur = () => {
    setActive(false)
  }

  return (
    <Tag className={classNames.join(' ')} {...props}>
      {href &&
        <Anchor href={href} className="hcc-tile-primary-anchor" onFocus={onFocus} onBlur={onBlur}/>
      }
      {(category || title || subtitle || cta) &&
        <div className="hcc-tile-content">
          {category && <span className="hcc-tile-category">{category}</span>}
          {title && <h2 className="hcc-tile-title">{title}</h2>}
          {subtitle && <p className="hcc-tile-subtitle">{subtitle}</p>}
          {cta &&
            <div className="hcc-tile-cta">{cta.map((el, i) =>
              <Button key={`${el.href}-${i}`} label={el.label} suffix={<i><Svg type="ctrl-right"/></i>} href={el.href} onFocus={onFocus} onBlur={onBlur}/>)}
            </div>
          }
        </div>
      }
      {children}
    </Tag>
  )

}

Tile.defaultProps = {
  tag         : 'li',
  size        : '1/2',
  cardinality : 'sw',
  href        : null,
  category    : null,
  title       : null,
  subtitle    : null,
  cta         : null,
  className   : '',
  children    : null,
}

Tile.propTypes = {
  tag         : PropTypes.string,
  size        : PropTypes.string,
  cardinality : PropTypes.string,
  href        : PropTypes.string,
  category    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title       : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cta         : PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  className   : PropTypes.string,
  children    : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Tile
