import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Container from "../components/Container"
import Jumbotron from "../components/Jumbotron"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import Tile from "../components/Tile"
import Tiles from "../components/Tiles"
import { formatLink, formatResourceLinkHref, formatResourceLinkLabel } from "../helpers"
import "../css/resources.scss"

const ResourcesPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query ResourcesPageQuery {
          allDatoCmsGlobal {
            edges {
              node {
                resourcesLinks {
                  resourcesShortLabel
                  title
                  slug
                  slugPath
                }
              }
            }
          }
        }
      `}
      render={data => {
        let allResourceLinks = data.allDatoCmsGlobal.edges[0].node.resourcesLinks

        return (
          <Site
            title     = "Resources"
            className = "hcc-resources"
          >
            <article>
              <Section tag="header" className="hcc-resources-intro">
                <Container>
                  <Jumbotron content="Sermons &amp; general&nbsp;info"/>
                </Container>
              </Section>
              <Section className="hcc-resources-index">
                <Container>
                  <Tiles>
                    <Tile
                      size        = "1/1"
                      cardinality = "c"
                      href        = {formatLink('', 't')}
                      title       = "Talks"
                      subtitle    = "Sermons from our main and student services"
                      cta         = {[{ label : "Listen Now", href : formatLink('', 't') }]}
                    />
                  </Tiles>
                  <ul class="hcc-resource-list">
                    <li>
                      <SiteLink href="/about/">
                        <strong>About</strong>
                      </SiteLink>
                    </li>
                    {allResourceLinks.map((dataLink, iLink) => {
                      return (
                        <li key={`resources-page-resource-link-${iLink}`}>
                          <SiteLink href={formatResourceLinkHref(dataLink)}>
                            <strong>{formatResourceLinkLabel(dataLink)}</strong>
                          </SiteLink>
                        </li>
                      )
                    })}
                    <li>
                      <SiteLink href="/give/">
                        <strong>Give</strong>
                      </SiteLink>
                    </li>
                  </ul>
                </Container>
              </Section>
            </article>
          </Site>
        )
      }}
    />
  )
}

export default ResourcesPage
